/* eslint-disable react/forbid-dom-props */
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import logo from '../../media/mmb-logo.png';
import APP_NAME from '../../constants/appName';
import pdfStyles from './pdfStyles';
import { sortRecommendations } from '../../utils/pdfFunctions';
import formatter from '../../utils/formatter';
import PDFPageNumber from './PDFPageNumber';
import PDFHeader from './PDFHeader';
import type CommonProps from '../../types/commonProps';

interface PDFPage3Props extends CommonProps {
  sessionID: string;
}

const PDFPage3 = ({ strengths, inLines, opportunities, sessionID }: PDFPage3Props) => {
  const { t } = useTranslation();

  const sortedDimensionsArr = sortRecommendations(t, [
    ...(strengths || ''),
    ...(inLines || ''),
    ...(opportunities || ''),
  ]);

  const recommendationsOutput = sortedDimensionsArr.map(
    ({ dimensionName, recommendation }, index) => {
      const rec = (
        <div style={pdfStyles.recommendation}>
          <Text style={pdfStyles.recommendationHeader}>{t(dimensionName)}</Text>
          {recommendation.map(({ title, wording }) => (
            <div key={t(title)}>
              <Text style={pdfStyles.recommendationSubHeader}>{t(title)}</Text>
              <Text style={pdfStyles.recommendationText}>{t(wording)}</Text>
            </div>
          ))}
        </div>
      );

      if (index > 0 && index % 2 === 0) {
        return (
          <div key={t(dimensionName)}>
            <View style={pdfStyles.header} break>
              <Image style={pdfStyles.logo} src={logo} fixed />
              <Text style={pdfStyles.productTitle} fixed>
                {APP_NAME}
              </Text>
            </View>
            {rec}
          </div>
        );
      }
      return (
        <>
          <div key={t(dimensionName)}>{rec}</div>
          <PDFPageNumber sessionID={sessionID} />
        </>
      );
    }
  );

  const strengthsText = strengths?.length && (
    <Text style={pdfStyles.text}>
      {t('results.noStyle.high', { categories: formatter.format(strengths) })}
    </Text>
  );

  const opportunitiesText = opportunities?.length && (
    <Text style={pdfStyles.text}>
      {t('results.noStyle.low', { categories: formatter.format(opportunities) })}
    </Text>
  );

  const inLinesText = inLines?.length && (
    <Text style={pdfStyles.text}>
      {t('results.noStyle.immediate', { categories: formatter.format(inLines) })}
    </Text>
  );

  return (
    <Page style={pdfStyles.body}>
      <PDFHeader />
      <Text style={pdfStyles.headerSmall}>{t('results.recommendations')}</Text>
      {strengthsText}
      {opportunitiesText}
      {inLinesText}
      {recommendationsOutput}
      <PDFPageNumber sessionID={sessionID} />
    </Page>
  );
};

export default PDFPage3;
