import { loadImage, createCanvas } from 'canvas';
import type { TFunction } from 'i18next';
import Highcharts from 'highcharts';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import type { ReactElement } from 'react';
import ITEMS from '../constants/recommendationsData';
import type { DimensionItem } from '../types/dimensionItem';
import type { ChartOptions } from '../types/data';
import i18n from '../translation/i18n';
import TRANSLATED_PDFS from '../constants/translatedPDFs';

export const sortRecommendations = (t: TFunction, data: string[]) => {
  const englishLanguage = { lng: 'en' };
  const dimensionsInEnglish = ITEMS.dimensions.map((item) => ({
    ...item,
    dimensionName: t(item.dimensionName, englishLanguage),
    recommendation: item.recommendation.map((rec) => ({
      title: t(rec.title, englishLanguage),
      wording: t(rec.wording, englishLanguage),
    })),
  }));

  const output: DimensionItem[] = [];
  data.forEach((element) => {
    dimensionsInEnglish.forEach(({ dimensionName }, index) => {
      if (dimensionName === element) {
        output.push(ITEMS.dimensions[index]);
      }
    });
  });
  return output;
};

export const getSvgUrl = (svgString: string) =>
  URL.createObjectURL(
    new Blob([svgString], {
      type: 'image/svg+xml',
    })
  );

export const svgUrlToPng = (svgUrl: string, callback: (arg: string) => void) => {
  const width = 600;
  const height = 400;
  const canvas = createCanvas(width, height);
  const context = canvas.getContext('2d');
  loadImage(svgUrl).then((image) => {
    context.drawImage(image, 0, 0);
    const imgData = canvas.toDataURL('image/png');
    callback(imgData);
  });
};

export const createChart = (
  myDiv: HTMLElement,
  chartOptions: ChartOptions,
  setPngUrl: (data: string) => void
) => {
  const chartSvg = Highcharts.chart(myDiv, chartOptions).getSVG();
  const svgUrl1 = getSvgUrl(chartSvg);
  svgUrlToPng(svgUrl1, (imgData: string) => {
    setPngUrl(imgData);
  });
};

export const downloadPdf = async (file: ReactElement, fileName: string) => {
  const blob = pdf(file);
  const output = await blob.toBlob();
  saveAs(output, fileName);
};

export const privacyPolicyPDFHandler = () =>
  window
    .open(process.env.PUBLIC_URL + (TRANSLATED_PDFS[i18n.language] || TRANSLATED_PDFS.en), '_blank')
    ?.focus();
