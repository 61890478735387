import { useTranslation } from 'react-i18next';
import styles from './HomeFooter.module.scss';
import type { ChartOptions } from '../../types/data';
import type CommonProps from '../../types/commonProps';
import Button from '../../UI/Button/Button';
import { privacyPolicyPDFHandler } from '../../utils/pdfFunctions';
import PDFfileDownload from '../../components/PDF/PDFfileDownload';

interface HomeFooterProps extends CommonProps {
  options: ChartOptions;
  sessionID: string;
  modalOpenHandler: () => void;
}

const HomeFooter = ({
  strengths,
  inLines,
  opportunities,
  options,
  sessionID,
  modalOpenHandler,
}: HomeFooterProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonContainerInner}>
          <div className={styles.button}>
            <Button
              dataTestId="sessionButton"
              className={styles.pageButton}
              onClick={modalOpenHandler}
            >
              {t('other.yourSession')}
            </Button>
          </div>
          <div className={styles.button}>
            <Button
              dataTestId="privacyNoticeButton"
              className={styles.pageButton}
              onClick={privacyPolicyPDFHandler}
            >
              {t('other.privacyNotice')}
            </Button>
          </div>
          <div className={styles.button}>
            <PDFfileDownload
              options={options}
              strengths={strengths}
              inLines={inLines}
              opportunities={opportunities}
              sessionID={sessionID}
            />
          </div>
        </div>
      </div>
      <div className={styles.legalContainer}>
        <div className={styles.legal}>{t('other.disclaimer')}</div>
      </div>
    </div>
  );
};

export default HomeFooter;
