import { useTranslation } from 'react-i18next';
import styles from './Pages.module.scss';
import sections from '../data/sectionDesc';
import Section from '../../components/Section/Section';
import HomeHeader from './HomeHeader';
import APP_NAME from '../../constants/appName';
import type { AvailableLanguages } from '../../types/availableLanguages';

interface HomeResultsProps {
  activeCard: null | number;
  activeTileHandler: (arg: number) => void;
  availableLanguages: AvailableLanguages;
  preferredLanguageCode: string;
}

const HomeResults = ({
  activeCard,
  activeTileHandler,
  availableLanguages,
  preferredLanguageCode,
}: HomeResultsProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.leftOfPage}>
      <HomeHeader
        availableLanguages={availableLanguages}
        preferredLanguageCode={preferredLanguageCode}
      />
      <h2 data-testid="appTitle">{APP_NAME}</h2>
      <p>{t('other.collatedResults')}</p>
      {sections.map(({ title, description }, index) => (
        <Section
          key={title}
          dataTestId={`dimension-${index}`}
          title={t(title)}
          desc={t(description)}
          isActive={activeCard === index}
          setIsActive={() => activeTileHandler(index)}
        />
      ))}
    </div>
  );
};

export default HomeResults;
