import { Route, Routes } from 'react-router-dom';
import styles from './HomeCharts.module.scss';
import Chart1 from '../Chart/Chart1';
import Chart2 from '../Chart/Chart2';
import PageNav from '../PageNav/PageNav';
import Results from '../Results/Results';
import RecommendationsText from '../Recommendations/RecommendationText';
import type { ChartOptions } from '../../types/data';
import type CommonProps from '../../types/commonProps';

interface HomeChartsProps extends CommonProps {
  options: ChartOptions;
  clientInfoFields: string[];
  isEap: boolean;
}

const HomeCharts = ({
  strengths,
  inLines,
  opportunities,
  options: [chart1Options, chart2Options],
  clientInfoFields,
  isEap,
}: HomeChartsProps) => {
  const isShowCompanyTab = clientInfoFields?.length > 0;

  return (
    <div className={styles.rightOfPage}>
      <PageNav showCompanyTab={isShowCompanyTab} />
      <div className={styles.chartContainer}>
        <Routes>
          <Route path="/" element={<Chart1 options={chart1Options} />} />
          <Route path="/cohort-results" element={<Chart2 options={chart2Options} />} />
          <Route
            path="/recommendations"
            element={
              <Results
                strengths={strengths}
                inLines={inLines}
                opportunities={opportunities}
                isEap={isEap}
                showCompanyTab={isShowCompanyTab}
              />
            }
          />
          <Route
            path="/company-resources"
            element={<RecommendationsText clientInfoFields={clientInfoFields} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default HomeCharts;
