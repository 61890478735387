import i18next from 'i18next';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import type { AvailableLanguages } from '../../types/availableLanguages';

const FORM_STYLING = { m: 0, minWidth: 120 };

interface LanguageSelectionProps {
  availableLanguages: AvailableLanguages;
  preferredLanguageCode: string;
}

const LanguageSelection = ({
  availableLanguages,
  preferredLanguageCode,
}: LanguageSelectionProps) => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    preferredLanguageCode || availableLanguages[0].code
  );

  const languageChangeHandler = (event: SelectChangeEvent<string>) => {
    i18next.changeLanguage(event.target.value);
    setCurrentLanguage(event.target.value);
  };

  const menuItems = availableLanguages.map(({ code, name }) => (
    <MenuItem key={name} value={code}>
      {name}
    </MenuItem>
  ));

  return (
    <div>
      <FormControl variant="standard" sx={FORM_STYLING}>
        <InputLabel id="simple-select-standard-label">{t('other.language')}</InputLabel>
        <Select
          labelId="simple-select-standard-label"
          id="simple-select-standard"
          data-testid="languageDropdown"
          value={currentLanguage}
          onChange={languageChangeHandler}
          label={t('other.language')}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelection;
