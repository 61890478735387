import { useEffect, useState } from 'react';
import styles from './Pages.module.scss';
import HomeCharts from '../../components/HomeCharts/HomeCharts';
import HomeResults from './HomeResults';
import MessageModal from '../../UI/Modal/MessageModal';
import type { ChartOptions } from '../../types/data';
import type CommonProps from '../../types/commonProps';
import type { AvailableLanguages } from '../../types/availableLanguages';
import i18n from '../../translation/i18n';
import HomeFooter from './HomeFooter';

interface HomePageProps extends CommonProps {
  options: ChartOptions;
  clientInfoFields: string[];
  isEap: boolean;
  sessionID: string;
  availableLanguages: AvailableLanguages;
  preferredLanguageCode: string;
}

const HomePage = ({
  strengths,
  inLines,
  opportunities,
  options,
  clientInfoFields,
  isEap,
  sessionID,
  availableLanguages,
  preferredLanguageCode,
}: HomePageProps) => {
  const [modalDisplay, setModalDisplay] = useState<boolean>(false);
  const [activeCard, setActiveCard] = useState<null | number>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalDisplay(true);
    }, 1500);

    i18n.changeLanguage(availableLanguages.length > 1 ? preferredLanguageCode : 'en');

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const activeTileHandler = (title: number) => {
    setActiveCard(title === activeCard ? null : title);
  };

  const modalCloseHandler = () => {
    setModalDisplay(false);
  };

  const modalOpenHandler = () => {
    setModalDisplay(true);
  };

  return (
    <>
      {modalDisplay && <MessageModal sessionID={sessionID} modalClose={modalCloseHandler} />}
      <div className={styles.pageFlex} data-testid="home-page">
        <HomeResults
          activeCard={activeCard}
          activeTileHandler={activeTileHandler}
          availableLanguages={availableLanguages}
          preferredLanguageCode={preferredLanguageCode}
        />
        <HomeCharts
          strengths={strengths}
          inLines={inLines}
          opportunities={opportunities}
          options={options}
          clientInfoFields={clientInfoFields}
          isEap={isEap}
        />
      </div>
      <HomeFooter
        strengths={strengths}
        inLines={inLines}
        opportunities={opportunities}
        options={options}
        sessionID={sessionID}
        modalOpenHandler={modalOpenHandler}
      />
    </>
  );
};

export default HomePage;
